import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useMediaQuery, useTheme } from '@mui/material';

export default function ChsrtsPieData({ donetsk, lugansk }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Проверяем, является ли устройство мобильным

  const data = [
    { id: 0, value: donetsk, label: 'Донецьк' },
    { id: 1, value: lugansk, label: 'Луганськ' }
  ];

  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      width={isMobile ? 300 : 400}  // Устанавливаем ширину в зависимости от устройства
      height={isMobile ? 150 : 200} // Соответственно уменьшаем высоту
    />
  );
}
