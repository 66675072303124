import * as React from 'react';
import Button from '@mui/material/Button';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export default function OpenBtn(props) {
  return (
    <div>
      <Button>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <FolderOpenIcon />
        </a>
      </Button>
    </div>
  );
}
