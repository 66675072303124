import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import React from 'react'


export default function ChartsPer(props) {
  return (
    <Gauge
    value={props.value}
    valueMax={300}
    startAngle={-110}
    endAngle={110}
    sx={{
        [`& .${gaugeClasses.valueText}`]: {
        fontSize: 40,
        transform: 'translate(0px, 0px)',
        },
    }}
    text={
        ({ value, valueMax }) => `${value} / ${valueMax}`
    }
    />
  )
}
