import React, { useState, useEffect } from 'react';
import CTable from './CTable';
import ChsrtsPieData from './ChartsPieData';
import HeadS from './HeadS';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import ChartsPer from './ChartsPer';
import { Grid } from '@mui/material';

export default function Layout() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataDonetsk, setDataDonetsk] = useState([]);
    const [dataLugansk, setDataLugansk] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.itdocumentum.com.ua/registry');
                const fetchedData = response.data;

                setData(fetchedData);
                const donetskData = fetchedData.filter(item => item.book_dist === "Донецька");
                const luganskData = fetchedData.filter(item => item.book_dist === "Луганська");

                setDataDonetsk(donetskData);
                setDataLugansk(luganskData);
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ backgroundColor: 'background.default', width: '100%', overflowX: 'hidden' }}>
            <HeadS data={data} setFilteredData={setFilteredData} />
            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                <div className="layout-container">
                    <Grid container spacing={2} sx={{ backgroundColor: 'background.default', padding: '1em', overflowX: 'hidden' }}>
                        <Grid item xs={12} md={9}>
                            <ChsrtsPieData donetsk={dataDonetsk.length} lugansk={dataLugansk.length} style={{ margin: '5em' }} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ChartsPer value={data.length} />
                        </Grid>
                    </Grid>
                    <CTable data={filteredData.length > 0 ? filteredData : data} rowsPerPage={10} />
                </div>
            )}
        </div>

    );
}
