import * as React from "react";

const SVGLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="60px"
    height="70px"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 21000 29700"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <style type="text/css">
        {`
          .str0 {stroke:#2B2A29;stroke-width:10;stroke-miterlimit:22.9256}
          .fil0 {fill:#fff} /* золотой цвет */
        `}
      </style>
    </defs>
    <g id="Слой_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path
        className="fil0 str0"
        d="M10397.91 4371.01c-5272.28,0 -9546.23,4297.36 -9546.23,9598.44 0,4888.22 3634.38,8924.06 8334.11,9521.82 231.28,29.43 686.4,56.25 763.01,61.28 0,0 16.51,2158.77 17.76,2494.12l1026.33 -14.35c72.84,-0.9 -24.05,-2479.05 47.19,-2485.87 670.96,-65.04 1320.31,-190.1 1941.75,-369.89 4078.55,-1180.27 6962.3,-4724.49 6962.3,-9207.11 0,-5301.08 -4273.94,-9598.44 -9546.22,-9598.44zm498 1162.69l55.35 5791.18 4490.07 -4136.24c395.9,-364.69 -3609.89,-2113.29 -4545.42,-1654.94zm-977.97 10929.69l38.4 -3684.35 -5363.97 -4981.96c-557.57,-517.92 -2975.17,3922.27 -2273.79,4667.25 2600.35,2762 4740.64,4741.17 7590.12,7172.59 25.84,-45.58 -1.8,-2874.78 9.24,-3173.53zm999.68 2.96l-44.41 -3664.16 5369.97 -5002.15c597.94,-556.94 2984.05,3807.88 2273.79,4667.25 -2416.7,2924.03 -4740.72,4741.26 -7590.11,7172.59 -37.05,31.58 8.43,-2696.34 -9.24,-3173.53zm40.1 4319.88l10.14 1851.6c1.34,243.84 2822.38,-177.18 5132.23,-2189.28 1619.87,-1411.1 2988.27,-3559.2 2957.23,-7004.91 -0.27,-34.63 -1678.36,1681.23 -3521.26,3369.01 -1908.2,1747.44 -3993.95,3478.28 -4578.34,3973.58zm-918.67 -15252.53l-55.35 5791.18 -4490.07 -4136.24c-395.9,-364.69 3609.89,-2113.29 4545.42,-1654.94zm-85.49 15252.62l-10.14 1851.6c-1.26,242.76 -2829.56,-171.08 -5141.48,-2159.4 -1638.26,-1408.86 -3017.33,-3564.05 -2947.98,-7034.79 0.63,-35.8 1837.6,1827.01 3777.74,3603.61 1839.13,1684.01 3771.64,3272.56 4321.86,3738.98z"
      />
    </g>
  </svg>
);

export default SVGLogo;
